.signature-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  padding: 20px;
}
.signature-show-wrapper {
  display: flex;
  flex-direction: column;
  padding: 20px;
}
.signature-items-wrapper {
  margin: 15px 0px;
}
.text-field-width{
  width: 16rem;
}
